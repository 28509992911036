<template>
  <div class="auth-wrapper auth-v2 mobile-only">
    <!-- Video Background -->
    <video autoplay muted loop id="bg-video">
      <source :src="backgroundVideo" type="video/mp4" />
      {{ $t('Seu navegador não suporta o elemento de vídeo.') }}
    </video>

    <div class="d-flex justify-content-center align-items-center vh-100">
      <b-row class="auth-inner m-0 w-100 justify-content-center">
        <!-- Login-->
        <b-col sm="10" class="d-flex align-items-center auth-bg px-2 p-lg-2">
          <b-col sm="12" class="px-xl-2 mx-auto">
            <div class="text-center mb-2">
              <!-- Brand logo-->
              <b-link class="brand-logo">
                <b-img fluid :src="imgLogo2" alt="Logo New X Coins" style="max-height: 10rem" />
              </b-link>
              <!-- /Brand logo-->
            </div>
            <b-card-title class="mb-1 font-weight-bold text-white" title-tag="h2">
              {{ $t('Bem-vindo, Investidor!') }}
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t('Estamos prontos para impulsionar seus ganhos hoje!') }}
            </b-card-text>

            <!-- form -->
            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form class="auth-login-form mt-2" @submit.prevent="login">
                <!-- email -->
                <label for="login-password">{{ $t("E-mail") }}</label>
                <b-form-group>
                  <validation-provider #default="{ errors }" mode="eager" name="Email" vid="email"
                    rules="required|min:4|max:80|email">
                    <b-form-input id="login-email" v-model="userEmail"
                      :state="errors.length > 0 || alertLogin ? false : null" name="login-email"
                      placeholder="example@gmail.com" @change="alertLogin = false" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ $t("Senha") }}</label>
                    <b-link :to="{ name: 'forgot-password' }">
                      <small>{{ $t("Esqueceu sua senha?") }}</small>
                    </b-link>
                  </div>
                  <validation-provider #default="{ errors }" mode="eager" name="Password" vid="password"
                    rules="required|min:4|max:80">
                    <b-input-group class="input-group-merge">
                      <b-form-input id="login-password" v-model="password"
                        :state="errors.length > 0 || alertLogin ? false : null" class="form-control-merge"
                        :type="passwordFieldType" name="login-password" placeholder="Senha"
                        @change="alertLogin = false" />
                      <b-input-group-append is-text>
                        <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility" />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- alert -->
                <b-alert v-height-fade.appear :show="alertLogin" class="mb-1" variant="danger">
                  <div class="alert-body">
                    <div>
                      {{ $t("Esqueceu sua senha?") }}
                      <b-link :to="{ name: 'forgot-password' }">
                        <small>{{ $t("Clique aqui") }}</small>
                      </b-link>
                    </div>
                  </div>
                </b-alert>
                <!-- submit buttons -->
                <b-button type="submit" variant="primary" block :disabled="invalid || loading">
                  <div v-if="!loading">
                    {{ $t("Entrar") }}
                  </div>
                  <div v-else>
                    <b-spinner small type="grow"></b-spinner>
                    {{ $t("Carregando...") }}
                  </div>
                </b-button>
              </b-form>

              <b-modal id="modal-2fa" :title="$t('Código de segurança')" hide-footer no-close-on-backdrop centered>
                <div class="p-1 text-center">
                  <div class="mb-1">
                    <h3><b><span>{{ $t('Autenticação em') }} </span><br><span style="color: #03E4FF">
                          {{ $t('dois fatores') }} ✉️
                        </span></b></h3>
                    <small style="color: #B4B7BD">{{ $t('Nós enviamos um código para o e-mail:') }}<br><span
                        class="text-white"><b>{{
                          userEmail.toLowerCase() }}</b></span></small>
                  </div>
                  <b-col cols="12" class="mt-2">
                    <b-form-group :label="$t('Digite abaixo o código de segurança')">
                      <div class="d-flex justify-content-center">
                        <template v-for="(code, index) in codes">
                          <b-form-input :ref="`codeInput${index}`" v-model="codes[index]" maxlength="1" size="2"
                            class="text-center"
                            style="width: 50px; height: 55px; border: 2px solid #03E4FF; border-radius: 3px; margin: 0.25rem; font-size: 1.1rem; font-weight: bold; color: #fff"
                            @input="focusNext(index)" @paste="handlePaste">
                          </b-form-input>
                        </template>
                      </div>
                      <validation-provider #default="{ errors }" mode="eager" name="securityCode" vid="securityCode">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                    <div class="d-flex justify-content-center">
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="text-white"
                        :disabled="codes.join('').length !== 6 || loading" @click="() => login(securityCode)">
                        <div v-if="!loading">
                          {{ $t("Entrar") }}
                        </div>
                        <div v-else>
                          <b-spinner small type="grow"></b-spinner>
                          {{ $t("Carregando...") }}
                        </div>
                      </b-button>
                    </div>
                  </b-col>
                </div>
              </b-modal>

              <b-modal id="modal-2fa-app" :title="$t('Autenticação de 2 Fatores por APP')" hide-footer
                no-close-on-backdrop centered>
                <div class="p-1 text-center">
                  <div class="mb-1">
                    <h3><b><span>{{ $t('Autenticação em') }} </span><br><span style="color: #03E4FF">
                          {{ $t('dois fatores por aplicativo') }}
                        </span></b></h3>
                  </div>
                  <b-col cols="12" class="mt-2">
                    <b-form-group :label="$t('Por favor, digite o código do aplicativo')">
                      <div class="d-flex justify-content-center">
                        <template>
                          <b-form-input v-model="securityCode_app" maxlength="6" class="text-center">
                          </b-form-input>
                        </template>
                      </div>
                      <validation-provider #default="{ errors }" mode="eager" name="securityCode_app"
                        vid="securityCode_app">
                        <span class="text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                    <div class="d-flex justify-content-center">
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="text-white"
                        :disabled="securityCode_app.length !== 6 || loading" @click="() => login(securityCode)">
                        <div v-if="!loading">
                          {{ $t("Entrar") }}
                        </div>
                        <div v-else>
                          <b-spinner small type="grow"></b-spinner>
                          {{ $t("Carregando...") }}
                        </div>
                      </b-button>
                    </div>
                  </b-col>
                </div>
              </b-modal>
            </validation-observer>
          </b-col>

        </b-col>
        <b-col sm="12" class=" text-center">
          <div style="margin-top: 0.25rem">
            <span class="">
              {{ $t("Versão") }}</span>
            <span class="text-primary" style="margin-left: 0.3rem">{{
              appVersion
            }}</span>
          </div>
        </b-col>

        <!-- /Login-->
      </b-row>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email, min, max } from "@/libs/validations";
import ProjectLogo from "@/layouts/logo/LogoProjectX.vue";
import GoogleLogo from "@/layouts/logo/LogoGoogle.vue";
import ProjectXLogo from "@/layouts/logo/LogoProjectX.vue";
import { version } from "../../../package";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { mapGetters, mapActions } from "vuex";
import DeviceDetector from "device-detector-js";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import Locale from "@/layouts/components/Locale.vue";

import { heightFade } from "@core/directives/animations";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ProjectLogo,
    GoogleLogo,
    ProjectXLogo,
    ValidationProvider,
    ValidationObserver,
    Locale,
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "height-fade": heightFade,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      appVersion: version,
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      loading: false,
      securityCode: '',
      codes: Array(6).fill(''),
      focusedIndex: 0,
      securityCode_app: '',

      // validation rules
      required,
      email,
      min,
      max,

      //Alert
      apiErrorMessage: "",
      alertLogin: false,

      // Background video
      backgroundVideo: require("@/assets/videos/background.mp4"),
    };
  },
  computed: {
    ...mapGetters(["returnLocale"]),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      return require("@/assets/images/business_plan/img_login3.png");
    },
    imgLogo() {
      if (store.state.appConfig.layout.skin === "dark") {
        return require("@/assets/images/logo/newxcoins_logo_light.png");
      } else {
        return require("@/assets/images/logo/newxcoins_logo_dark.png");
      }
    },
    imgLogo2() {
      return require("@/assets/images/logo/newxcoins_logo_light.png");
    },
  },
  watch: {
    returnLocale: function (oldVal, newVal) {
      localize(oldVal);
    },
  },
  mounted() {
    this.verifyInternet();
  },
  methods: {
    ...mapActions(["authenticateUser", "recoverUserData"]),
    focusNext(index) {
      if (index < 5) {
        this.$refs[`codeInput${index + 1}`][0].focus();
      }
    },
    handlePaste(event) {
      event.preventDefault();

      const pastedData = event.clipboardData.getData('text/plain');

      if (pastedData.length === 6) {
        for (let i = 0; i < 6; i++) {
          this.$set(this.codes, i, pastedData.charAt(i));
        }
      }
    },
    verifyInternet() {
      setInterval(() => {
        if (!navigator.onLine) this.loading = false;
      }, 3000);
    },
    showAlert() {
      this.alertLogin = true;
    },
    hideAlert() {
      this.alertLogin = false;
    },
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          const data = {
            email: this.userEmail.toLowerCase(),
            password: this.password,
          };
          const fullCode = this.codes.join('');
          if (fullCode) {
            data.securityCode = fullCode
          }
          if (this.securityCode_app.length === 6) {
            data.securityCode_app = this.securityCode_app
          }
          this.authenticateUser(data)
            .then(() => {
              this.recoverUserData()
                .then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      icon: "CoffeeIcon",
                      variant: "success",
                      title:
                        this.$i18n.t("Bem-vindo ") +
                        "@" +
                        response.data.user.username,
                    },
                  });
                  this.$ability.update(response.data.user.roles);
                  this.loading = false;
                  this.$router.push("/");
                })
                .catch((error) => {
                  console.log('error', error)
                  if (error.openModal2fa) {
                    this.loading = false;
                    this.$bvModal.show("modal-2fa");
                  } else {
                    this.showAlert();
                    this.apiErrorMessage = error;
                    this.loading = false;
                  }
                });
            })
            .catch((error) => {
              console.log('error', error)
              if (error.openModal2fa) {
                this.loading = false;
                this.$bvModal.show("modal-2fa");
              } else if (error.openModal2fa_app) {
                this.loading = false;
                this.$bvModal.show("modal-2fa-app");
              } else {
                this.showAlert();
                this.apiErrorMessage = error;
                this.loading = false;
                this.$refs.loginForm.setErrors(error.errors);
              }
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.auth-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

#bg-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background: no-repeat;
  background-size: cover;
}

.auth-inner {
  width: 100%;
  max-width: 550px;
  background: rgba(0, 22, 27, 0.5);
  /* Manter a opacidade baixa */
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
  /* Aumentar a sombra */
  color: #fff;
  /* Tornar as letras mais legíveis com cor branca */
}

.auth-inner * {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  /* Adicionar sombra a todos os textos dentro do card */
}

label {
  color: #fff;
  /* Tornar os labels brancos */
}

.small {
  color: #fff;
  /* Tornar os pequenos textos brancos */
}

.b-form-input,
.b-form-group,
.b-input-group,
.b-button {
  background: rgb(0, 25, 39);
  /* Fundo escuro e semitransparente para inputs e botões */
  color: #fff;
  /* Tornar o texto dos inputs e botões brancos */
  border: 1px solid #fff;
  /* Bordas brancas para inputs e botões */
}

.b-link {
  color: #fff;
  /* Tornar os links brancos */
}

.b-alert {
  background: rgba(255, 0, 0, 0.5);
  /* Fundo semitransparente para alertas */
  color: #fff;
  /* Tornar o texto dos alertas branco */
}

.b-card-title,
.b-card-text {
  color: #fff;
  /* Tornar o texto branco */
}

.b-card-title {
  font-size: 2rem;
  /* Aumentar o tamanho da fonte para destacar mais */
  font-weight: bold;
  /* Tornar o texto em negrito */
}

.b-card-text {
  font-size: 1.2rem;
  /* Aumentar o tamanho da fonte para destaque adicional */
}

@media (max-width: 767px) {
  .mobile-only {
    height: 100vh;
    /* Preencher toda a altura da tela em dispositivos móveis */
    border-radius: 0;
    /* Remover borda arredondada em dispositivos móveis */
    padding: 1rem;
    /* Ajustar padding para dispositivos móveis */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .auth-inner {
    height: 100vh;
    /* Preencher toda a altura da tela em dispositivos móveis */
    border-radius: 0;
    /* Remover borda arredondada em dispositivos móveis */
    padding: 1rem;
    /* Ajustar padding para dispositivos móveis */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
